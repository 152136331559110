import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Table,
  Icon,
  Progress,
  Spin,
  Popconfirm,
  Tooltip,
} from "antd";

import LangContext from "modules/shared/context/langContext";
import * as helper from "./helperFx";
import FilterAllDataTab from "./FilterAllDataTab";
import { fetchVechicles, fetchAllConnection } from "./apiClient";

const columnStyle = { fontSize: "12px", fontWeight: "400" };

class AllData extends Component {
  state = {
    visible: false,
    loadVehicleWebsocket: false,
    vehicleFilterLists: [],
    displayModal: true,
    vehicleOnly: [],
    selectedRowKeys: [],
    tableId: "websocket_all_download_queue_table_win"
  };

  getVehicleList = async () => {
    fetchAllConnection((allconnect) => {
      let list = allconnect;
      let allhwid = list.length > 0 ? list.join(",") : "";

      let params = {
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        company_id: this.props.auth.profile.company_id,
        online_vehicles: allhwid,
      };

      fetchVechicles(params, (statusCode, res) => {
        if (statusCode !== 200) {
          this.setState({ loadVehicleWebsocket: false });
          return;
        }

        let rec = res.data.data.filter((x) => x.title !== "fileopt");
        let all = [];
        rec.map((x) => {
          x.children.map((c) => all.push(c));
        });

        this.setState({
          vehicleFilterLists: res.data.data,
          loadVehicleWebsocket: false,
          vehicleOnly: all,
        });
      });
    });
  };

  handleLoadVehicleList = () => {
    this.setState(
      {
        visible: true,
        loadVehicleWebsocket: true,
      },
      this.getVehicleList
    );
  };

  componentDidMount() {
    if (navigator.platform === "MacIntel") {
      this.setState({ tableId: "websocket_all_download_queue_table_mac" })
    }
  }

  render() {
    const column = [
      {
        title: "#",
        dataIndex: "row_number",
        width: "5%",
        align: "center",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        sorter: false,
        width: "17%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.f.fileName}
          </LangContext.Consumer>
        ),
        dataIndex: "filename",
        sorter: false,
        // render: name => `${ name.first } ${ name.last }`,
        width: "13%",
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.date_time}
          </LangContext.Consumer>
        ),
        dataIndex: "file_datetime",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
        width: "10%",
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.completeDate}
          </LangContext.Consumer>
        ),
        dataIndex: "download_finish_at",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
        width: "10%",
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.canDownloadUpTo}
          </LangContext.Consumer>
        ),
        dataIndex: "expired_at",
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
        width: "10%",
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.camera_position}
          </LangContext.Consumer>
        ),
        dataIndex: "camera_source",
        sorter: false,
        width: "9%",
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>{helper.getCameraText(text, record.device_types_id)}</div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.f.file_size}
          </LangContext.Consumer>
        ),
        dataIndex: "file_size",
        width: "9%",
        align: "center",
        render(text, record, index) {
          let size = text / 1024 / 1024;
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{size.toFixed(2) + " MB"}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.s.status}</LangContext.Consumer>
        ),
        dataIndex: "queue_status",
        width: "9%",
        align: "center",
        render: (text, record, index) => {
          if (parseInt(text) === helper.queueStatusId("downloading")) {
            return (
              <Progress
                type="circle"
                percent={parseInt(record.download_progress)}
                width={50}
              />
            );
          }

          let style = helper.getColColorStatus(text)

          return {
            props: {
              style: style,
            },
            children: <div>{helper.getQueueStatus(text, record.remark)}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "download_link",
        width: "7%",
        align: "center",
        render: (text, record, index) => {
          // const styletool = {
          //   padding: "0px 1px 0px 0px",
          //   marginLeft: "5px",
          //   marginRight: "5px",
          // };

          let deleteBtn = (
            <Tooltip
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.delete}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
            //style={styletool}
            >
              <Popconfirm
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                  </LangContext.Consumer>
                }
                onConfirm={() => {
                  this.props.action.delete(
                    record.id,
                    record.hwid,
                    record.filename
                  );
                }}
              >
                <Button type="link" style={{ padding: "0px 15px 0px 5px" }}>
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </Tooltip>
          );

          if (record.queue_status === helper.queueStatusId("downloading")) {
            //firmware เก่ายกเลิกไม่ได้
            if (record.old_format) {
              return (
                <LangContext.Consumer>
                  {(i18n) => i18n.d.downloading}
                </LangContext.Consumer>
              );
            }
            return (
              <Fragment>
                <Button
                  type="link"
                  onClick={(e) => {
                    this.props.action.cancel(
                      record.id,
                      record.hwid,
                      record.filename
                    );
                  }}
                  style={{ padding: "0px 15px 0px 5px" }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.cancel}
                  </LangContext.Consumer>
                </Button>
              </Fragment>
            );
          }

          if (record.queue_status === helper.queueStatusId("waiting")) {
            return <Fragment>
              <Button
                type="link"
                onClick={(e) => {
                  this.props.action.delete(record.id, record.hwid, record.filename, true);
                }}
                style={{ padding: "0px 15px 0px 5px" }}
              >
                <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
              </Button>
            </Fragment>
          }

          if (
            helper.queueStatusId("error") === record.queue_status &&
            record.old_format
          ) {
            return deleteBtn
          }

          return (
            <Fragment>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.download}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              //style={styletool}
              >
                <Button
                  type="link"
                  onClick={(e) => {
                    if (
                      [
                        helper.queueStatusId("cancel"),
                        helper.queueStatusId("error"),
                      ].includes(record.queue_status)
                    ) {
                      this.props.action.requeue(
                        record.id,
                        record.hwid,
                        record.filename
                      );
                    } else if (record.download_link) {
                      helper.downloadFileVideo(record.download_link);
                    }
                  }}
                  style={{ padding: "0px 15px 0px 5px" }}
                >
                  <Icon type="download" />
                </Button>
              </Tooltip>
              {/* {deleteBtn} */}
            </Fragment>
          );
        },
      },
    ];

    return (
      <Fragment>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            {/* AutoComplete */}
            <FilterAllDataTab
              onSubmit={this.props.onSearch}
              loadData={this.handleLoadVehicleList}
              vehicleLists={this.state.vehicleFilterLists}
              loadVehicle={this.state.loadVehicleWebsocket}
              vehicleOnly={this.state.vehicleOnly}
              vehicleId={this.props.vehicleId}
              plateNo={this.props.plateNo}
              dataSource={this.props.dataSource.length}
              userId={this.props.auth.profile.id}
            />
          </Col>
          <Col span={12} align="right">
            <LangContext.Consumer>
              {(i18n) => i18n.manageOTP.updateAt}
            </LangContext.Consumer>{" "}
            : {this.props.updateAt}
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24} align="right">
            <Spin spinning={this.props.loading}>
              <Table
                bordered
                id={this.state.tableId}
                size="small"
                columns={column}
                rowKey={(record) => "all_" + record.row_number.toString()}
                dataSource={this.props.dataSource}
                loading={this.props.loading}
                pagination={false}
                scroll={{ y: `calc(100vh - 200px)` }}
              />
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps, {})(AllData);
