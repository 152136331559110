import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Modal,
  TimePicker,
  Checkbox,
} from 'antd'

import { checkPermissions } from '../../../../lib/helper'
import { StyledSearchForm } from '../../../../styled/common-styled'
import FormDivider from '../../../shared/components/FormDivider'
import { getlocalstorage } from './../../../../constants/local_storage'
import Dailyiconsiam from '../../dailyiconsiam/components/Forms'
import Summaryiconsiam from '../../summaryiconsiam/components/Forms'
import Truckcurrentlocation from '../../truckcurrentlocation/components/Forms'
import Driverinformation from '../../driverinformation/components/Forms'
import Numberofdaysworked from '../../numberofdaysworked/components/Forms'
import Distfromlastmaintenance from '../../distfromlastmaintenance/components/Forms'
import Maintenance from '../../maintenance/components/Forms'
import Temperature from '../../temperature/components/Forms'
import Drivingdistance from '../../drivingdistance/components/Forms'
import Event from '../../event/components/Forms'
import Truckusage from '../../truckusage/components/Forms'
import Parking from '../../parking/components/Forms'
import Comparevehiclescco from '../../comparevehiclescco/components/Forms'
import Drivinghour from '../../drivinghour/components/Forms'
import Dozeoffrisk from '../../dozeoffrisk/components/Forms'
import Summarylinenotification from '../../summarylinenotification/components/Forms'
import FuelRemainRate from '../../fuelremainrate/components/Forms'
import Truckengineon from '../../truckengineon/components/Forms'
import Truckengineoff from '../../truckengineoff/components/Forms'

const InputGroup = Input.Group
const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const format = 'HH:mm'

export default class Forms extends Component {
  state = {
    list_report_schedules_id: '',
    schedule_type_id: 'daily',
    attachment_type: 'excel',
    email_subject: '',
    to_email: '',
    cc_email: '',
    body_email: '',
    start_time: moment('00:00', format),
    end_time: moment('23:59', format),
    previous_data: true,
    visible: false,
    matching_name: '',
    vehicleLists: [],
    vehicleTypeLists: [],
    loading: false,
    days: [],
    day: '1',
    week: '1',
    month: '1',
    time: moment('00:00', format),
    filter_report: '',
    schedule_type: 'daily',
    policyLists: [],
  }

  loadVehicle = (vehicletypeID, loading) => {
    this.setState({ loading })
    let self = this
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem('profile'),
      'company_id'
    )

    const vehicle_visibility = getlocalstorage(
      localStorage.getItem('profile'),
      'vehicle_visibility'
    )

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ vehicleLists: res.data, loading: false })
      })
  }

  loadVehicleType = () => {
    let self = this
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem('profile'),
      'company_id'
    )

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        company_id: COMPANY_ID,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ vehicleTypeLists: res.data })
      })
  }

  loadPolicy = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpolicybycompanyid`,
      {
        company_id: this.props.auth.profile.company_id,
      },
      {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      }
    )

    this.setState({ policyLists: data.data })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {
    this.props.loadReportSchedulereport()
    let days = []
    for (let i = 1; i <= 31; i++) {
      days.push(i)
    }
    this.setState({ days })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { schedulereport } = nextProps
    const { dataSchedulereport } = schedulereport

    if (
      this.props.schedulereport.check_schedulereport !==
      nextProps.schedulereport.check_schedulereport
    ) {
      this.setState({
        list_report_schedules_id: dataSchedulereport.list_report_schedules_id,
        schedule_type_id: dataSchedulereport.schedule_type,
        schedule_type: dataSchedulereport.schedule_type,
        attachment_type: dataSchedulereport.attachment_type,
        email_subject: dataSchedulereport.email_subject,
        to_email: dataSchedulereport.to_email,
        cc_email: dataSchedulereport.cc_email,
        body_email: dataSchedulereport.body_email,
        day:
          dataSchedulereport.day == 0 || dataSchedulereport.day == null
            ? '1'
            : dataSchedulereport.day.toString(),
        week:
          dataSchedulereport.week == 0 || dataSchedulereport.week == null
            ? '1'
            : dataSchedulereport.week.toString(),
        month:
          dataSchedulereport.month == 0 || dataSchedulereport.month == null
            ? '1'
            : dataSchedulereport.month.toString(),
        time: moment(dataSchedulereport.time, format),
        start_time: moment(dataSchedulereport.start_time, format),
        end_time: moment(dataSchedulereport.end_time, format),
        previous_data: dataSchedulereport.previous_data,
        filter_report: dataSchedulereport.filter,
        matching_name: dataSchedulereport.matching_name,
      })
    }
    return true
  }

  onReportTypeChange = (value, option, setFieldValue) => {
    //const { loadVehicle } = this.props;
    setFieldValue('list_report_schedules_id', value)
    this.setState({
      matching_name: option.props.matching_name,
      filter_report: '',
    })
    if (value !== '') {
      this.showModal()
    }
  }

  onScheduleTypeChange = (value, setFieldValue) => {
    setFieldValue('schedule_type_id', value)
    this.setState({ schedule_type: value })
  }

  onSubmit = (values, loading) => {
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem('profile'),
      'company_id'
    )

    const vehicle_visibility = getlocalstorage(
      localStorage.getItem('profile'),
      'vehicle_visibility'
    )

    const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')
    let event_name = undefined
    let event = undefined
    let find_address_new = undefined

    const {
      list_vehicle_id,
      vehicle_type_id,
      driver_name,
      driver_code,
      op_of_amount_date,
      amount_date,
      distance_more_than,
      list_part_type_id,
      event_id,
      event_type,
      find_address,
      vehicle_code,
      plate_no,
      group_idle,
      group_speed,
      vehicle_id,
      policy_id,
      sheet_format,
    } = values
    if (event_id !== undefined) {
      event_name = event_id.event_id
      event = event_id.key
    }
    if (find_address !== undefined && find_address !== '') {
      find_address_new = find_address.target.checked
    }

    let temp_obj = {
      list_vehicle_id,
      company_id: COMPANY_ID,
      vehicle_type_id,
      vehicle_visibility,
      user_id,
      driver_name,
      driver_code,
      op_of_amount_date,
      amount_date,
      distance_more_than,
      list_part_type_id,
      event_name,
      event,
      event_type,
      find_address: find_address_new,
      vehicle_code,
      plate_no,
      group_idle,
      group_speed,
      vehicle_id,
      policy_id,
    }

    let list_vehicle_type_id_str = [
      'report_numberofdaysworked',
      'report_distfromlastmaintenance',
      'report_drivingdistance',
      'report_dozeoffrisk',
      'report_fuelremainrate',
    ]

    if (list_vehicle_type_id_str.includes(this.state.matching_name)) {
      temp_obj = {
        ...temp_obj,
        vehicle_type_id: `${vehicle_type_id}`,
      }
    }

    if (this.state.matching_name === 'report_truckengineon') {
      temp_obj = {
        ...temp_obj,
        engine_on_time_start_at: '00:00',
        engine_on_time_end_at: '23:59',
        max_speed: 0,
      }
    }

    if (this.state.matching_name === 'report_truckengineoff') {
      temp_obj = {
        ...temp_obj,
        engine_off_time_start_at: '00:00',
        engine_off_time_end_at: '23:59',
      }
    }

    if (this.state.matching_name === 'report_event') {
      let temp_event = event.toString()
      let arr_event = temp_event.split(',')
      for (let i = 0; i < arr_event.length; i++) {
        arr_event[i] = parseInt(arr_event[i])
      }

      temp_obj = {
        ...temp_obj,
        arr_event: arr_event,
        sheet_format,
        event_name: event_id.label || '',
      }
    }

    let json_obj = JSON.stringify(temp_obj)

    this.setState({ filter_report: json_obj, visible: false })
  }

  validateEmail = (value) => {
    let warning
    if (!value) {
      warning = i18n.e.email_require
    } else {
      let emails = value.value.split(',')
      let i
      for (i = 0; i < emails.length; i++) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emails[i])) {
          warning = i18n.e.email_invalid
          break
        }
      }
    }
    return warning
  }

  render() {
    const { schedulereport, onSubmit, id } = this.props
    const { schedulereportLoading, reportLists } = schedulereport

    return (
      <Spin spinning={schedulereportLoading}>
        <Row>
          <StyledSearchForm>
            <Modal
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.s.setting_filter}
                </LangContext.Consumer>
              }
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              cancelButtonProps={{ style: { display: 'none' } }}
              okButtonProps={{ style: { display: 'none' } }}
              width={800}
            >
              {this.state.matching_name === 'report_dailyiconsiam' ? (
                <Dailyiconsiam
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  loading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_summaryiconsiam' ? (
                <Summaryiconsiam
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  loading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_truckcurrentlocation' ? (
                <Truckcurrentlocation
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  truckcurrentlocationLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_driverinformation' ? (
                <Driverinformation
                  onSubmit={this.onSubmit}
                  driverinformationLoading={this.state.loading}
                  dataedit={this.state.filter_report}
                  driverLists={this.props.driverLists}
                  loadDriver={this.props.loadDriver}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_numberofdaysworked' ? (
                <Numberofdaysworked
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  numberofdaysworkedLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_distfromlastmaintenance' ? (
                <Distfromlastmaintenance
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  distfromlastmaintenanceLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPartType={this.props.loadPartType}
                  partTypeLists={this.props.partTypeLists}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_maintenance' ? (
                <Maintenance
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  maintenanceLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPartType={this.props.loadPartType}
                  partTypeLists={this.props.partTypeLists}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_temperature' ? (
                <Temperature
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  temperatureLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_drivingdistance' ? (
                <Drivingdistance
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  drivingdistanceLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_event' ? (
                <Event
                  onSubmit={this.onSubmit}
                  vehicleLists={this.props.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  eventLoading={this.props.eventLoading}
                  policyLists={this.props.policyLists}
                  eventLists={this.props.eventLists}
                  vehiclebygroupLists={this.props.vehiclebygroupLists}
                  vehiclebypolicyLists={this.props.vehiclebypolicyLists}
                  loadVehicle={this.props.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPolicy={this.props.loadPolicy}
                  loadEvents={this.props.loadEvents}
                  loadVehiclebypolicy={this.props.loadVehiclebypolicy}
                  loadVehiclebygroupid={this.props.loadVehiclebygroupid}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_truckusage' ? (
                <Truckusage
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  truckusageLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_parking' ? (
                <Parking
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  parkingLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_comparevehiclescco' ? (
                <Comparevehiclescco
                  onSubmit={this.onSubmit}
                  comparevehiclesccostatusLoading={false}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_drivinghour' ? (
                <Drivinghour
                  onSubmit={this.onSubmit}
                  vehicleLists={this.props.vehicleLists}
                  drivinghourLoading={false}
                  loadVehicle={this.props.loadVehicle}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_dozeoffrisk' ? (
                <Dozeoffrisk
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  dozeoffriskLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_summarylinenotification' ? (
                <Summarylinenotification
                  onSubmit={this.onSubmit}
                  policyLists={this.state.policyLists}
                  loadPolicy={this.loadPolicy}
                  summaryLineNotificationLoading={this.state.loading}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_fuelremainrate' ? (
                <FuelRemainRate
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  fuelremainrateLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_truckengineon' ? (
                <Truckengineon
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  truckengineonLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_truckengineoff' ? (
                <Truckengineoff
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  truckengineoffLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  call='schedule'
                />
              ) : (
                ''
              )}
            </Modal>
            <Formik
              enableReinitialize={true}
              initialValues={{
                list_report_schedules_id: this.state.list_report_schedules_id,
                schedule_type_id: this.state.schedule_type_id,
                schedule_type: this.state.schedule_type_id,
                attachment_type: this.state.attachment_type,
                email_subject: this.state.email_subject,
                to_email: this.state.to_email,
                cc_email: this.state.cc_email,
                day: this.state.day,
                week: this.state.week,
                month: this.state.month,
                time: this.state.time,
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                previous_data: this.state.previous_data,
                body_email: this.state.body_email,
              }}
              validate={(values) => {
                let errors = {}

                // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.to_email)) {
                //   errors.email = 'Invalid email address';
                // }

                return errors
              }}
              validationSchema={yup.object().shape({
                list_report_schedules_id: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.pleaseChooseReport}
                    </LangContext.Consumer>
                  ),
                email_subject: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.email_subject_require}
                    </LangContext.Consumer>
                  ),
                to_email: yup
                  .string()
                  .email(this.validateEmail)
                  .required(
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.email_require}
                    </LangContext.Consumer>
                  ),
                // cc_email: yup
                //   .string()
                //   .email(this.validateEmail)
                //   .required("กรุณากรอกอีเมล์"),
              })}
              onSubmit={(values, { resetForm }) => {
                if (this.state.filter_report !== '') {
                  values.filter_report = this.state.filter_report
                  const loading = true
                  onSubmit(values, loading)
                } else {
                  Modal.info({
                    content: (
                      <LangContext.Consumer>
                        {(i18n) => i18n.a.alert_setting_filter}
                      </LangContext.Consumer>
                    ),
                    onOk() {},
                  })
                }
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <Row>
                  <LangContext.Consumer>
                    {(i18n) => (
                      <Form onSubmit={handleSubmit}>
                        <FormDivider>
                          <LangContext.Consumer>
                            {(i18n) => i18n.e.email}
                          </LangContext.Consumer>
                        </FormDivider>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.e.email_subject}
                                </LangContext.Consumer>
                              }
                              //  style={{ marginBottom: "0px" }}
                              required={true}
                              validateStatus={
                                touched.email_subject &&
                                errors.email_subject &&
                                'warning'
                              }
                              help={
                                touched.email_subject && errors.email_subject
                              }
                            >
                              <Input
                                type='Text'
                                name='email_subject'
                                autoComplete='off'
                                value={values.email_subject}
                                onChange={(e) => {
                                  setFieldValue('email_subject', e.target.value)
                                }}
                                onBlur={handleBlur}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              required='true'
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.t.to_email}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.to_email && errors.to_email && 'warning'
                              }
                              help={touched.to_email && errors.to_email}
                            >
                              <TextArea
                                placeholder='info@gmail.com,test@gmail.com'
                                name='to_email'
                                rows={6}
                                onChange={(value) =>
                                  setFieldValue('to_email', value.target.value)
                                }
                                value={values.to_email}
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              // required="false"
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.c.cc_email}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.cc_email && errors.cc_email && 'warning'
                              }
                              help={touched.cc_email && errors.cc_email}
                            >
                              <TextArea
                                placeholder='info@gmail.com,test@gmail.com'
                                name='cc_email'
                                rows={6}
                                onChange={(value) =>
                                  setFieldValue('cc_email', value.target.value)
                                }
                                value={values.cc_email}
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              //required="true"
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.b.body_email}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.body_email &&
                                errors.body_email &&
                                'warning'
                              }
                              help={touched.body_email && errors.body_email}
                            >
                              <TextArea
                                name='body_email'
                                rows={4}
                                onChange={(value) =>
                                  setFieldValue(
                                    'body_email',
                                    value.target.value
                                  )
                                }
                                value={values.body_email}
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <FormDivider>
                          <LangContext.Consumer>
                            {(i18n) => i18n.r.report}
                          </LangContext.Consumer>
                        </FormDivider>

                        <Row gutter={24}>
                          <Col sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.r.report}
                                </LangContext.Consumer>
                              }
                              required={true}
                              // style={{ paddingBottom: "5px" }}
                              validateStatus={
                                touched.list_report_schedules_id &&
                                errors.list_report_schedules_id &&
                                'warning'
                              }
                              help={
                                touched.list_report_schedules_id &&
                                errors.list_report_schedules_id
                              }
                            >
                              <InputGroup compact>
                                <Select
                                  // onChange={value => setFieldValue("list_report_schedules_id", value)}

                                  onChange={(value, option) =>
                                    this.onReportTypeChange(
                                      value,
                                      option,
                                      setFieldValue
                                    )
                                  }
                                  value={values.list_report_schedules_id}
                                  style={{ width: '83%' }}
                                  disabled={id !== '' ? true : false}
                                >
                                  <Option value=''>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.p.pleaseSelect}
                                    </LangContext.Consumer>
                                  </Option>
                                  {reportLists.map((v) => (
                                    <Option
                                      key={v.id}
                                      value={v.id}
                                      matching_name={v.matching_name}
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) =>
                                          i18n.scheduleReport[v.matching_name]
                                        }
                                      </LangContext.Consumer>
                                    </Option>
                                  ))}
                                </Select>
                                <Button
                                  icon='setting'
                                  style={{ width: '17%' }}
                                  onClick={this.showModal}
                                >
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.s.setting_filter}
                                  </LangContext.Consumer>
                                </Button>
                              </InputGroup>
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.a.attachment_type}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.attachment_type &&
                                errors.attachment_type &&
                                'warning'
                              }
                              help={
                                touched.attachment_type &&
                                errors.attachment_type
                              }
                            >
                              <Select
                                onChange={(value) =>
                                  setFieldValue('attachment_type', value)
                                }
                                value={values.attachment_type}
                              >
                                <Option value='excel'>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.e.excel}
                                  </LangContext.Consumer>
                                </Option>
                                <Option value='pdf'>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.p.pdf}
                                  </LangContext.Consumer>
                                </Option>
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.s.schedule_type}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.schedule_type_id &&
                                errors.schedule_type_id &&
                                'warning'
                              }
                              help={
                                touched.schedule_type_id &&
                                errors.schedule_type_id
                              }
                            >
                              <InputGroup compact>
                                <Select
                                  //showSearch
                                  style={{ width: '40%' }}
                                  // onChange={value =>
                                  //   setFieldValue("schedule_type_id", value)
                                  // }
                                  onChange={(value) =>
                                    this.onScheduleTypeChange(
                                      value,
                                      setFieldValue
                                    )
                                  }
                                  value={values.schedule_type_id}
                                >
                                  <Option value='daily'>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.r.report_daily}
                                    </LangContext.Consumer>
                                  </Option>
                                  <Option value='weekly'>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.r.report_weekly}
                                    </LangContext.Consumer>
                                  </Option>
                                  {/* <Option value="monthly">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.r.report_monthly
                                        }
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="yearly">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.r.report_yearly
                                        }
                                      </LangContext.Consumer>
                                    </Option> */}
                                </Select>
                                {this.state.schedule_type === 'monthly' ||
                                this.state.schedule_type === 'yearly' ? (
                                  <Select
                                    onChange={(value) =>
                                      setFieldValue('day', value)
                                    }
                                    value={values.day}
                                    style={{ width: '10%' }}
                                  >
                                    {this.state.days.map((v) => (
                                      <Option key={v} value={v}>
                                        {v}
                                      </Option>
                                    ))}
                                  </Select>
                                ) : (
                                  ''
                                )}
                                {this.state.schedule_type === 'weekly' ? (
                                  <Select
                                    onChange={(value) =>
                                      setFieldValue('week', value)
                                    }
                                    value={values.week}
                                    style={{ width: '15%' }}
                                  >
                                    <Option value='1'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_sunday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='2'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_monday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='3'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_tuesday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='4'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_wednesday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='5'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_thursday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='6'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_friday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='7'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.w.w_saturday}
                                      </LangContext.Consumer>
                                    </Option>
                                  </Select>
                                ) : (
                                  ''
                                )}

                                {this.state.schedule_type === 'yearly' ? (
                                  <Select
                                    onChange={(value) =>
                                      setFieldValue('month', value)
                                    }
                                    value={values.month}
                                    style={{ width: '20%' }}
                                  >
                                    <Option value='1'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_january}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='2'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_february}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='3'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_march}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='4'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_april}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='5'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_may}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='6'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_june}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='7'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_july}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='8'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_august}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='9'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_september}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='10'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_october}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='11'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_november}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value='12'>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.m_december}
                                      </LangContext.Consumer>
                                    </Option>
                                  </Select>
                                ) : (
                                  ''
                                )}
                                <TimePicker
                                  placeholder={i18n.n.notificationTime}
                                  // value={values.time}
                                  style={{ width: '15%' }}
                                  onChange={(value) =>
                                    setFieldValue('time', value)
                                  }
                                  format={format}
                                />
                              </InputGroup>
                            </FormItem>
                          </Col>
                        </Row>
                        {this.state.schedule_type === 'daily' ? (
                          <Row gutter={24}>
                            <Col sm={24} md={14} lg={14} xl={14}>
                              <FormItem
                                label={
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.d.duration_time}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.duration_time &&
                                  errors.duration_time &&
                                  'warning'
                                }
                                help={
                                  touched.duration_time && errors.duration_time
                                }
                              >
                                <InputGroup>
                                  <TimePicker
                                    value={values.start_time}
                                    style={{ width: '15%' }}
                                    onChange={(value) =>
                                      setFieldValue('start_time', value)
                                    }
                                    format={format}
                                  />
                                  <span>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.t.to}
                                    </LangContext.Consumer>
                                  </span>
                                  <TimePicker
                                    value={values.end_time}
                                    style={{ width: '15%' }}
                                    onChange={(value) =>
                                      setFieldValue('end_time', value)
                                    }
                                    format={format}
                                  />
                                </InputGroup>
                              </FormItem>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}

                        {this.state.schedule_type === 'daily' ? (
                          <Row gutter={24}>
                            <Col sm={24} md={14} lg={14} xl={14}>
                              <FormItem
                                validateStatus={
                                  touched.previous_data &&
                                  errors.previous_data &&
                                  'warning'
                                }
                                help={
                                  touched.previous_data && errors.previous_data
                                }
                              >
                                <Checkbox
                                  checked={values.previous_data}
                                  onChange={(value) =>
                                    setFieldValue(
                                      'previous_data',
                                      value.target.checked
                                    )
                                  }
                                >
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.s.send_data_before_delivery}
                                  </LangContext.Consumer>
                                </Checkbox>
                              </FormItem>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}

                        <Row style={{ marginTop: '20px', marginBottom: '5px' }}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item>
                              <Button
                                type='defualt'
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                <Link to='/schedulereport'>{i18n.b.back}</Link>
                              </Button>

                              <Button type='primary' htmlType='submit'>
                                {i18n.s.save}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    )
  }
}
