import React, { useState } from "react";
import { Button, Icon, Modal, Row, Col } from "antd";
const ModalDownloadVideoQueue = ({
  code,
  downloadVideoVisible,
  handleCancel,
  handleOk,
  expireDate,
}) => {
  return (
    <Modal
      title="ลิ้งที่ถูกสร้าง"
      visible={downloadVideoVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={900}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} align="left">
          ลิ้งที่ดาวน์โหลดไฟล์ :{" "}
          {`${window.location.host}/videodownloadqueuevideolist/${code}`}{" "}
          <Icon
            className="download_video_queue_copy"
            type="copy"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.host}/videodownloadqueuevideolist/${code}`
              );
            }}
          />
        </Col>
        <Col span={24} align="left">
          วันที่หมดอายุ : {expireDate}
        </Col>
        <Col span={24} align="center">
          <Button
            onClick={() => {
              handleCancel();
            }}
            type="primary"
          >
            ปิด
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalDownloadVideoQueue;
