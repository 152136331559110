import React, { useEffect, useState, useRef } from "react";
import { Table, Spin } from "antd";

const Detail = (props) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const containerRef = useRef(null);

  useEffect(() => {
    fetchData(page);
  }, [page]);

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [third])

  const fetchData = async (page) => {
    if (loading) return;

    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      const total = 100; // Simulate total items in the dataset
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;

      if (startIndex >= total) {
        setHasMore(false);
        setLoading(false);
        return;
      }

      const newData = Array.from({ length: pageSize }, (_, i) => ({
        key: startIndex + i + 1,
        name: `Name ${startIndex + i + 1}`,
        age: 20 + ((startIndex + i) % 10),
        address: `Address ${startIndex + i + 1}`,
      }));

      setTableData((prev) => [...prev, ...newData]);
      setLoading(false);
    }, 1000);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  return (
    <div
      style={{ height: "400px", overflow: "auto" }}
      onScroll={handleScroll}
      ref={containerRef}
    >
      <Spin spinning={loading && page === 1}>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false} // Disable default pagination
          scroll={{ y: 300 }} // Ensure the table is scrollable
        />
        {loading && page > 1 && (
          <div style={{ textAlign: "center", marginTop: 10 }}>Loading...</div>
        )}
      </Spin>
    </div>
  );
};

export default Detail;
