import React, { useState, useEffect } from "react";
import { Row, Col, Icon, Badge, Modal, Button, Spin } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { getPhoneNumber } from "../fetchAPI/apiClient";

const ModalPhoneNumber = ({ visiblePhoneNumber, onClosePhoneNumber, info }) => {
  console.log("ModalPhoneNumber", info);
  const { imei, plateNo, code } = info;
  const [data, setData] = useState({
    phone_number: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (visiblePhoneNumber) {
      setLoading(true);
      getPhoneNumber({ imei }, (statusCode, response) => {
        setData(response.data);
        setLoading(false);
      });
    }
  }, [visiblePhoneNumber]);

  return (
    <Modal
      title={
        <Row>
          <Col span={24} align="center">
            เบอร์อุปกรณ์ที่สามารถโทรเข้าหาได้
          </Col>
        </Row>
      }
      onCancel={() => {
        onClosePhoneNumber();
      }}
      footer={null}
      visible={visiblePhoneNumber}
    >
      <Spin spinning={loading}>
        <p>
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
          : {code}
        </p>
        <p>
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
          : {plateNo}
        </p>
        <p>
          <LangContext.Consumer>
            {(i18n) => i18n.p.phoneNumber}
          </LangContext.Consumer>
          : {!!data.phone_no ? data.phone_no : "-"}
        </p>
      </Spin>
    </Modal>
  );
};

export default ModalPhoneNumber;
