import { createTypes, createAction } from "lib/action";

const LOAD_SECBYSEC_REPORT = createTypes("secbysec_report", "load");

const loadSecBySecReport = {
  request: (data) => createAction(LOAD_SECBYSEC_REPORT.REQUEST, { data }),
  success: (data) => createAction(LOAD_SECBYSEC_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_SECBYSEC_REPORT.FAILURE),
};

export { loadSecBySecReport, LOAD_SECBYSEC_REPORT };
